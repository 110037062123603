.footerLinks {
    color: white;
    line-height: 2.4;
    font-weight: 500;
    font-size: 100%;

}

.bgDark {
    background-color: rgba(0, 0, 0, 0.842);
}