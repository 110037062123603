.bgImg {
    background-image: url('../../assets/images/section1Img.png');
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.bgImg2 {
    background-image: url('../../assets/images/section2Img.png');
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.bgImg3 {
    background-image: url('../../assets/images/section3Img.png');
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

@media only screen and (max-width: 768px) {}

.bgColor {
    background-color: #3B2E29;
    padding: 100px 80px;
}

.headings {
    position: absolute;
    bottom: 0
}

.headings {
    color: white
}

.headings h1 {
    width: 73%;
    font-size: 4vw;
    text-transform: uppercase;
}

.headings p {
    width: 73%;
    font-size: 1.3vw;
    font-weight: 500;
}

.headingFoure h1 {
    width: 73%;
    font-size: 4vw;
    text-transform: uppercase;
    font-weight: bold;
}

.headingFoure p {
    /* width: 93%; */
    margin-top: 12px;
    font-size: 1.2vw;
    font-weight: 400;
}

.responsiveImg {
    width: 120%;
    height: 100%;
}


@media only screen and (max-width: 768px) {
    .headings p {
        font-size: 2.3vw;
    }

    .headingFoure p {
        font-size: 2.3vw;
    }

    .bgImg, .bgImg2, .bgImg3 {
        height: 50vh;
    }
}