.navbar {
    /* font-family: 'DM Sans'; */
    background-color: rgba(0, 0, 0, 0.615);
    box-shadow: 2px 2px 2px #00000033;
    z-index: 9999;
}

.navbar .nav-link {
    text-decoration: none;
    position: relative;
}

.navbar .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: white;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
}

.navbar .nav-link:hover::after {
    transform: scaleX(1);
}

.custom-toggle-icon {
    width: 24px;
    height: 3px;
    background-color: white;
    transition: background-color 0.3s;
}

.custom-toggle-icon::before,
.custom-toggle-icon::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: background-color 0.3s;
}

.custom-toggle-icon::before {
    transform: translateY(-8px);
}

.custom-toggle-icon::after {
    transform: translateY(8px);
}

.custom-toggle-icon:hover,
.custom-toggle-icon:hover::before,
.custom-toggle-icon:hover::after {
    background-color: #FFF;
}

.header.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.615);
    box-shadow: 2px 2px 2px #00000033;
    z-index: 9999;
    border: none;
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}