:root {
    --primary-color: #D9A56D;
    --primary-bgColor: #D9A56D;
}

.mainColor {
    color: var(--primary-color);
}

.mainBgColor {
    background-color: var(--primary-bgColor);
}

/* button style */
.mainBgColor:hover {
    background-color: #e79135 !important;
    color: white !important;
}